//Alert
export const UPDATE_EVENTTYPECODE_ALERT = 'UPDATE_EVENTTYPECODE_ALERT';
export const UPDATE_COMPANY_ALERT = 'UPDATE_COMPANY_ALERT';
export const UPDATE_COUNTRY_ALERT = 'UPDATE_COUNTRY_ALERT';
export const UPDATE_AREA_ALERT = 'UPDATE_AREA_ALERT';
export const UPDATE_GROUPID_ALERT = 'UPDATE_GROUPID_ALERT';
export const UPDATE_GROUPNAME_ALERT = 'UPDATE_GROUPNAME_ALERT';

export const UPDATE_BATTERYGROUPID_ALERT = 'UPDATE_BATTERYGROUPID_ALERT';
export const UPDATE_BATTERYTYPE_ALERT = 'UPDATE_BATTERYTYPE_ALERT';
export const UPDATE_RECORDTIME_ALERT = 'UPDATE_RECORDTIME_ALERT';


export const RESET_EVENTTYPECODE_ALERT = 'RESET_EVENTTYPECODE_ALERT';
export const RESET_COMPANY_ALERT = 'RESET_COMPANY_ALERT';
export const RESET_COUNTRY_ALERT = 'RESET_COUNTRY_ALERT';
export const RESET_AREA_ALERT = 'RESET_AREA_ALERT';
export const RESET_GROUPID_ALERT = 'RESET_GROUPID_ALERT';
export const RESET_GROUPNAME_ALERT = 'RESET_GROUPNAME_ALERT';

export const RESET_BATTERYGROUPID_ALERT = 'RESET_BATTERYGROUPID_ALERT';
export const RESET_BATTERYTYPE_ALERT = 'RESET_BATTERYTYPE_ALERT';
export const RESET_RECORDTIME_ALERT = 'RESET_RECORDTIME_ALERT';

export const RESET_ALL_ALERT = 'RESET_ALL_ALERT';