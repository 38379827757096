//Alert
export const UPDATE_EVENTTYPECODE_ALERTUNSOLVED = 'UPDATE_EVENTTYPECODE_ALERTUNSOLVED';
export const UPDATE_COMPANY_ALERTUNSOLVED = 'UPDATE_COMPANY_ALERTUNSOLVED';
export const UPDATE_COUNTRY_ALERTUNSOLVED = 'UPDATE_COUNTRY_ALERTUNSOLVED';
export const UPDATE_AREA_ALERTUNSOLVED = 'UPDATE_AREA_ALERTUNSOLVED';
export const UPDATE_GROUPID_ALERTUNSOLVED = 'UPDATE_GROUPID_ALERTUNSOLVED';
export const UPDATE_GROUPNAME_ALERTUNSOLVED = 'UPDATE_GROUPNAME_ALERTUNSOLVED';

export const UPDATE_BATTERYGROUPID_ALERTUNSOLVED = 'UPDATE_BATTERYGROUPID_ALERTUNSOLVED';
export const UPDATE_BATTERYTYPE_ALERTUNSOLVED = 'UPDATE_BATTERYTYPE_ALERTUNSOLVED';
export const UPDATE_RECORDTIME_ALERTUNSOLVED = 'UPDATE_RECORDTIME_ALERTUNSOLVED';


export const RESET_EVENTTYPECODE_ALERTUNSOLVED = 'RESET_EVENTTYPECODE_ALERTUNSOLVED';
export const RESET_COMPANY_ALERTUNSOLVED = 'RESET_COMPANY_ALERTUNSOLVED';
export const RESET_COUNTRY_ALERTUNSOLVED = 'RESET_COUNTRY_ALERTUNSOLVED';
export const RESET_AREA_ALERTUNSOLVED = 'RESET_AREA_ALERTUNSOLVED';
export const RESET_GROUPID_ALERTUNSOLVED = 'RESET_GROUPID_ALERTUNSOLVED';
export const RESET_GROUPNAME_ALERTUNSOLVED = 'RESET_GROUPNAME_ALERTUNSOLVED';

export const RESET_BATTERYGROUPID_ALERTUNSOLVED = 'RESET_BATTERYGROUPID_ALERTUNSOLVED';
export const RESET_BATTERYTYPE_ALERTUNSOLVED = 'RESET_BATTERYTYPE_ALERTUNSOLVED';
export const RESET_RECORDTIME_ALERTUNSOLVED = 'RESET_RECORDTIME_ALERTUNSOLVED';

export const RESET_ALL_ALERTUNSOLVED = 'RESET_ALL_ALERTUNSOLVED';