//GroupManage
export const SET_ACTIVENUM_NBMANAGE = 'SET_ACTIVENUM_NBMANAGE';
export const RESET_ACTIVENUM_NBMANAGE = 'RESET_ACTIVENUM_NBMANAGE';


//BattManage P1539
export const UPDATE_COMPANY_P1539 = 'UPDATE_COMPANY_P1539';
export const UPDATE_BATTERYGROUPID_P1539 = 'UPDATE_BATTERYGROUPID_P1539';
export const UPDATE_NBID_P1539 = 'UPDATE_NBID_P1539';
export const UPDATE_MODIFYITEM_P1539 = 'UPDATE_MODIFYITEM_P1539';
export const UPDATE_MODIFYTIME_P1539 = 'UPDATE_MODIFYTIME_P1539';

export const RESET_COMPANY_P1539 = 'RESET_COMPANY_P1539';
export const RESET_BATTERYGROUPID_P1539 = 'RESET_BATTERYGROUPID_P1539';
export const RESET_NBID_P1539 = 'RESET_NBID_P1539';
export const RESET_MODIFYITEM_P1539 = 'RESET_MODIFYITEM_P1539';
export const RESET_MODIFYTIME_P1539 = 'RESET_MODIFYTIME_P1539';
export const RESET_ALL_P1539 = 'RESET_ALL_P1539';

//BattManage P1572
export const UPDATE_COMPANY_P1572 = 'UPDATE_COMPANY_P1572';
export const UPDATE_COUNTRY_P1572 = 'UPDATE_COUNTRY_P1572';
export const UPDATE_AREA_P1572 = 'UPDATE_AREA_P1572';
export const UPDATE_GROUPID_P1572 = 'UPDATE_GROUPID_P1572';
export const UPDATE_NBID_P1572 = 'UPDATE_NBID_P1572';
export const UPDATE_PREVNBID_P1572 = 'UPDATE_PREVNBID_P1572';
export const UPDATE_STARTTIME_P1572 = 'UPDATE_STARTTIME_P1572';

export const RESET_COMPANY_P1572 = 'RESET_COMPANY_P1572';
export const RESET_COUNTRY_P1572 = 'RESET_COUNTRY_P1572';
export const RESET_AREA_P1572 = 'RESET_AREA_P1572';
export const RESET_GROUPID_P1572 = 'RESET_GROUPID_P1572';
export const RESET_NBID_P1572 = 'RESET_NBID_P1572';
export const RESET_PREVNBID_P1572 = 'RESET_PREVNBID_P1572';
export const RESET_STARTTIME_P1572 = 'RESET_STARTTIME_P1572';
export const RESET_ALL_P1572 = 'RESET_ALL_P1572';