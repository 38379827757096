//BatteryData,BatteryHisotry
export const UPDATE_COMPANY_BATT = 'UPDATE_COMPANY_BATT';
export const UPDATE_COUNTRY_BATT = 'UPDATE_COUNTRY_BATT';
export const UPDATE_AREA_BATT = 'UPDATE_AREA_BATT';
export const UPDATE_GROUPID_BATT = 'UPDATE_GROUPID_BATT';
export const UPDATE_GROUPNAME_BATT = 'UPDATE_GROUPNAME_BATT';

export const UPDATE_BATTERYGROUPID_BATT = 'UPDATE_BATTERYGROUPID_BATT';
export const UPDATE_BATTERYTYPE_BATT = 'UPDATE_BATTERYTYPE_BATT';
export const UPDATE_BATTERYSTATUS_BATT = 'UPDATE_BATTERYSTATE_BATT';
export const UPDATE_RECORDTIME_BATT = 'UPDATE_RECORDTIME_BATT';

export const RESET_COMPANY_BATT = 'RESET_COMPANY_BATT';
export const RESET_COUNTRY_BATT = 'RESET_COUNTRY_BATT';
export const RESET_AREA_BATT = 'RESET_AREA_BATT';
export const RESET_GROUPID_BATT = 'RESET_GROUPID_BATT';
export const RESET_GROUPNAME_BATT = 'RESET_GROUPNAME_BATT';

export const RESET_BATTERYGROUPID_BATT = 'RESET_BATTERYGROUPID_BATT';
export const RESET_BATTERYTYPE_BATT = 'RESET_BATTERYTYPE_BATT';
export const RESET_BATTERYSTATUS_BATT = 'RESET_BATTERYSTATE_BATT';
export const RESET_RECORDTIME_BATT = 'RESET_RECORDTIME_BATT';

export const RESET_ALL_BATT = 'RESET_ALL_BATT';