//GroupManage
export const SET_ACTIVENUM_GROUPMANAGE = 'SET_ACTIVENUM_GROUPMANAGE';
export const RESET_ACTIVENUM_GROUPMANAGE = 'RESET_ACTIVENUM_GROUPMANAGE';

//BattManage P1502
export const UPDATE_COMPANY_P1502 = 'UPDATE_COMPANY_P1502';
export const UPDATE_COUNTRY_P1502 = 'UPDATE_COUNTRY_P1502';
export const UPDATE_AREA_P1502 = 'UPDATE_AREA_P1502';
export const UPDATE_GROUPID_P1502 = 'UPDATE_GROUPID_P1502';
export const UPDATE_GROUPNAME_P1502 = 'UPDATE_GROUPNAME_P1502';

export const RESET_COMPANY_P1502 = 'RESET_COMPANY_P1502';
export const RESET_COUNTRY_P1502 = 'RESET_COUNTRY_P1502';
export const RESET_AREA_P1502 = 'RESET_AREA_P1502';
export const RESET_GROUPID_P1502 = 'RESET_GROUPID_P1502';
export const RESET_GROUPNAME_P1502 = 'RESET_GROUPNAME_P1502';
export const RESET_ALL_P1502 = 'RESET_ALL_P1502';