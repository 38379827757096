//BattManage
export const SET_ACTIVENUM_BATTMANAGE = 'SET_ACTIVENUM_BATTMANAGE';
export const RESET_ACTIVENUM_BATTMANAGE = 'RESET_ACTIVENUM_BATTMANAGE';

//BattManage P1501
export const UPDATE_COMPANY_P1501 = 'UPDATE_COMPANY_P1501';
export const UPDATE_BATTERYGROUPID_P1501 = 'UPDATE_BATTERYGROUPID_P1501';
export const UPDATE_BATTERYTYPE_P1501 = 'UPDATE_BATTERYTYPE_P1501';
export const UPDATE_INSTALLDATE_P1501 = 'UPDATE_INSTALLDATE_P1501';

export const RESET_COMPANY_P1501 = 'RESET_COMPANY_P1501';
export const RESET_BATTERYGROUPID_P1501 = 'RESET_BATTERYGROUPID_P1501';
export const RESET_BATTERYTYPE_P1501 = 'RESET_BATTERYTYPE_P1501';
export const RESET_INSTALLDATE_P1501 = 'RESET_INSTALLDATE_P1501';
export const RESET_ALL_P1501 = 'RESET_ALL_P1501';

//BattManage P1505
export const UPDATE_COMPANY_P1505 = 'UPDATE_COMPANY_P1505';
export const UPDATE_BATTERYTYPENAME_P1505 = 'UPDATE_BATTERYTYPENAME_P1505';
export const RESET_COMPANY_P1505 = 'RESET_COMPANY_P1505';
export const RESET_BATTERYTYPENAME_P1505 = 'RESET_BATTERYTYPENAME_P1505';
export const RESET_ALL_P1505 = 'RESET_ALL_P1505';
