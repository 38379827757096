//Home
export const UPDATE_COMPANY_HOME = 'UPDATE_COMPANY_HOME';
export const UPDATE_COUNTRY_HOME = 'UPDATE_COUNTRY_HOME';
export const UPDATE_AREA_HOME = 'UPDATE_AREA_HOME';
export const UPDATE_GROUPID_HOME = 'UPDATE_GROUPID_HOME';

// 判斷通訊序號管理是否執行啟用 
export const UPDATE_GROUPIDLIST_STATUS = 'UPDATE_GROUPIDLIST_STATUS';

export const RESET_COMPANY_HOME = 'RESET_COMPANY_HOME';
export const RESET_COUNTRY_HOME = 'RESET_COUNTRY_HOME';
export const RESET_AREA_HOME = 'RESET_AREA_HOME';
export const RESET_GROUPID_HOME = 'RESET_GROUPID_HOME';

export const RESET_ALL_HOME = 'RESET_ALL_HOME';