export const SUBMIT = 'SUBMIT_LOGIN';
export const EXIT = 'SUMIT_LOGOUT';
export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_CURLANGUAGE = 'CHANGE_CURLANGUAGE'; //目前語系
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';   //變更預設語系
export const CHANGE_TIMEZONE = 'CHANGE_TIMEZONE';

export const FETCH_LOGIN_LOAD = 'FETCH_LOGIN_LOAD';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_ERROR = 'FETCH_LOGIN_ERROR';