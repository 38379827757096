//Command
export const SET_ACTIVENUM_COMMAND = 'SET_ACTIVENUM_COMMAND';
export const RESET_ACTIVENUM_COMMAND = 'RESET_ACTIVENUM_COMMAND';


//Command P1504
export const SET_TABLEHEADER_P1504 = 'SET_TABLEHEADER_P1504';
export const SET_AREALIST_P1504 = 'SET_AREALIST_P1504';
export const SET_GROUPLIST_P1504 = 'SET_GROUPLIST_P1504';
export const SET_SUBMITKEYLIST_P1504 = 'SET_SUBMITKEYLIST_P1504';
export const RESET_ALL_P1504= 'RESET_ALL_P1504';

//Command P1559
export const SET_TABLEHEADER_P1559 = 'SET_TABLEHEADER_P1559';

export const UPDATE_COMPANY_P1559 = 'UPDATE_COMPANY_P1559';
export const UPDATE_COUNTRY_P1559 = 'UPDATE_COUNTRY_P1559';
export const UPDATE_AREA_P1559 = 'UPDATE_AREA_P1559';
export const UPDATE_GROUPID_P1559 = 'UPDATE_GROUPID_P1559';
export const UPDATE_GROUPNAME_P1559 = 'UPDATE_GROUPNAME_P1559';

export const UPDATE_BATTERYGROUPID_P1559 = 'UPDATE_BATTERYGROUPID_P1559';
export const UPDATE_BATTERYTYPE_P1559 = 'UPDATE_BATTERYTYPE_P1559';
export const UPDATE_COMMAND_P1559 = 'UPDATE_COMMAND_P1559';
export const UPDATE_RESPONSE_P1559 = 'UPDATE_RESPONSE_P1559';
export const UPDATE_SENDTIME_P1559 = 'UPDATE_SENDTIME_P1559';

export const RESET_COMPANY_P1559 = 'RESET_COMPANY_P1559';
export const RESET_COUNTRY_P1559 = 'RESET_COUNTRY_P1559';
export const RESET_AREA_P1559 = 'RESET_AREA_P1559';
export const RESET_GROUPID_P1559 = 'RESET_GROUPID_P1559';
export const RESET_GROUPNAME_P1559 = 'RESET_GROUPNAME_P1559';

export const RESET_BATTERYGROUPID_P1559 = 'RESET_BATTERYGROUPID_P1559';
export const RESET_BATTERYTYPE_P1559 = 'RESET_BATTERYTYPE_P1559';
export const RESET_COMMAND_P1559 = 'RESET_COMMAND_P1559';
export const RESET_RESPONSE_P1559 = 'RESET_RESPONSE_P1559';
export const RESET_SENDTIME_P1559 = 'RESET_SENDTIME_P1559';

export const RESET_ALL_P1559 = 'RESET_ALL_P1559';